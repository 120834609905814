import axios from 'axios';
import { FormEvent, useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import toast from 'react-hot-toast';
import { RiLinkedinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
// import { LuLinkedin } from 'react-icons/lu';
// import { FaXTwitter } from 'react-icons/fa6';
// import { FaInstagram } from 'react-icons/fa';

function App() {
  const logo = require('./assets/logo.png');
  // const logob = require('./assets/logo-b.png');
  const card1 = require('./assets/card1.png');
  const g1 = require('./assets/g1.png');
  const g2 = require('./assets/g2.png');
  const g3 = require('./assets/g3.png');
  const hero = require('./assets/hero.png');
  // const socials = require('./assets/socials.png');
  const I1 = require('./assets/001.png');
  const I2 = require('./assets/002.png');
  const I3 = require('./assets/003.png');
  const I4 = require('./assets/004.png');
  const SOC = require('./assets/socials-f.png');

  const year = new Date();
  const mm = year?.getFullYear();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // This makes the scroll smooth
    });
  };

  useEffect(() => {
    axios.get('https://pitch-clerk.onrender.com/');
  }, []);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        'https://pitch-clerk.onrender.com/api/waitlist',
        {
          email,
        }
      );
      toast.success(response.data.message);
      setEmail('');
      setIsLoading(false);
    } catch (error) {
      toast.error('Failed to add to waitlist, try again');
      setIsLoading(false);
    }
  };

  const openLink = () => {
    window.open('https://auth.pitchclerk.co.uk/', '_blank');
  };

  const openX = () => {
    window.open('https://x.com/pitchclerk', '_blank');
  };

  const openInsta = () => {
    window.open('https://www.instagram.com/pitchclerk', '_blank');
  };

  const openLinkedIn = () => {
    window.open('https://www.linkedin.com/company/pitch-clerk/', '_blank');
  };

  const openMail = () => {
    window.open('mailto:contentreview@pitchclerk.co.uk', '_blank');
  };

  return (
    <div className="md:px-[150px] px-5">
      <div className="flex justify-between items-center w-full mt-[45px] mb-[65px]">
        <img src={logo} alt="" className="md:h-[60px] h-[30px]" />
        <button
          onClick={openLink}
          className={`ease-in duration-300 cursor-pointer md:px-[45px] px-[35px] text-white md:py-[23px] py-[15px] bg-[#000] rounded-[10px] text-whitee font-semibold md:text-three text-[12px]`}
        >
          Sign in
        </button>
      </div>
      <div className="container mx-auto text-center">
        <Fade triggerOnce direction="up">
          <span className="mx-auto p-2 text-[12px] rounded-full border-[1px] border-[#a020ef1a] text-[#A020EF] bg-[#E5C1FB]">
            Join the rising wave 🚀
          </span>
        </Fade>

        <div className="text-center md:text-two text-[34px] font-semibold trac-king-[2px] md:leading-[65px] leading-[37px] capitalize mt-[45px]">
          <Fade cascade triggerOnce direction="up" delay={500}>
            <div>Pitch Your music,</div>
            <div>get on playlist,</div>
          </Fade>
          <Fade triggerOnce direction="up" delay={1000}>
            <span className="text-[#A020EF]">Request advance</span>
          </Fade>
        </div>
        <Fade triggerOnce direction="up" delay={1500}>
          <p className="text-center md:text-three text-[20px] md:leading-[13.2px] text-[#656565] md:mt-[40px] mt-[20px] text-light">
            Distribute, publish and sync your music with ease
            {/* Empowering
            Artists, Simplifying Music Discovery */}
          </p>
        </Fade>
        <Fade triggerOnce direction="up" delay={2000}>
          <form
            onSubmit={handleSubmit}
            className="md:mt-[50px] my-[30px] mx-auto md:flex block justify-center md:gap-4"
          >
            <input
              type="email"
              value={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
              placeholder="Enter Email Address"
              className="ease-in focus:border-purple-500 md:text-[20px] text-[16px] outline-none duration-300 md:px-[20px] md:w-[450px] w-full border-[1px] border-[#CFCFCF] px-[15px] md:py-[23px] py-[15px] bg-[#FAFAFA] text-black rounded-[10px] md:text-three"
            />
            <button
              type="submit"
              disabled={email === '' || isLoading}
              className={`ease-in duration-300 cursor-pointer md:mt-0 mt-5 md:px-[45px] px-[35px] text-white md:py-[23px] py-[15px] bg-[#A020EF] rounded-[10px] text-whitee font-semibold md:text-three text-[12px] ${
                isLoading && 'opacity-50'
              }`}
            >
              {isLoading ? 'Submitting' : 'Join Waitlist'}
            </button>
          </form>
        </Fade>
      </div>
      <div className="container mx-auto ">
        {/* <Fade delay={3000}> */}
        <img src={hero} alt="" className="w-full mx-auto" />
        {/* </Fade> */}
      </div>
      <Fade duration={1000}>
        <div className="container mx-auto text-center my-[150px]">
          <div className="text-center text-[#656565] text-[20px] manrope mb-[50px]">
            <Fade delay={1000} duration={1000} direction="down">
              See what's coming
            </Fade>
          </div>
          <div className="image-wrapper flex md:flex-row flex-col gap-5">
            <div className="image-container md:w-4/12 w-12/12 relative group">
              <Fade delay={500} duration={1000} direction="left">
                <img
                  src={card1}
                  alt=""
                  className="transition-transform duration-3000 ease-in-out"
                />
              </Fade>
            </div>
            <div className="md:w-8/12 w-12/12">
              <Fade delay={500} duration={1000} direction="right">
                <div className="image-container relative group">
                  <img
                    src={g1}
                    alt=""
                    className="h-auto mb-5 transition-transform duration-3000 ease-in-out"
                  />
                </div>
              </Fade>
              <Fade delay={500} duration={1000} direction="right">
                <div className="image-container relative group">
                  <img
                    src={g2}
                    alt=""
                    className="h-auto mb-5 transition-transform duration-3000 ease-in-out"
                  />
                </div>
              </Fade>
              <Fade delay={500} duration={1000} direction="right">
                <div className="image-container relative group">
                  <img
                    src={g3}
                    alt=""
                    className="h-auto transition-transform duration-3000 ease-in-out"
                  />
                </div>
              </Fade>
            </div>
          </div>{' '}
        </div>
      </Fade>

      <div className="container mx-auto text-center">
        <Fade>
          <span className="mx-auto p-2 text-[12px] rounded-full border-[1px] border-[#a020ef1a] bg-[#E5C1FB] text-[#A020EF]">
            Wait, We Have More
          </span>
        </Fade>
        <Fade cascade delay={500} duration={1000} direction="up">
          <p className="capitalize md:text-[50px] text-[30px] font-medium md:leading-[52px] leading-[30px] mt-[27px] mb-[55px]">
            Join Top Artist and
            <br /> creators around the world
            <br />
            using pitchclerk
          </p>
        </Fade>
      </div>
      <div className="container mx-auto">
        <div className="grid md:grid-cols-4 grid-cols-2 relative rounded-[15px] overflow-hidden">
          <img src={I1} alt="" className="md:h-[600px] h-[250px] w-full" />
          <img src={I2} alt="" className="md:h-[600px] h-[250px] w-full" />
          <img src={I3} alt="" className="md:h-[600px] h-[250px] w-full" />
          <img src={I4} alt="" className="md:h-[600px] h-[250px] w-full" />
          <div className="absolute h-[100%] w-[100%] bg-gradient-to-b from-transparent to-[#000000e7]"></div>
          <button
            onClick={scrollToTop}
            className="absolute left-1/2 bottom-[40px] transform -translate-x-1/2 p-2 cursor-pointer md:px-[45px] px-[35px] text-white md:py-[23px] py-[15px] bg-[#A020EF] rounded-[10px] font-semibold md:text-three text-[12px] animate-pulse"
          >
            Join Waitlist
          </button>
        </div>
      </div>
      {/* <div className="container mx-auto">
        <div className="md:max-w-[1200px] mx-auto">
          <div className="flex md:flex-row flex-col">
            <div className="md:w-6/12 w-12/12 md:h-[489px] h-[400px] pink-border bg-black flex justify-center items-center">
              <img
                src={logob}
                alt="logo"
                className="md:h-[112px] md:w-[274px] w-[150px] h-[70px]"
              />
            </div>
            <div className="md:w-6/12 w-full md:h-[489px] h-[400px] black-border text-white bg-[#F9F9F9] md:rounded-r-[10px] rounded-b-[10px] flex justify-center items-center relative">
              <span>
                <div className="text-center text-white text-[22px] font-normal">
                  <span className="text-[#9E9E9E] underline underline-offset-4 cursor-pointer">
                    Viola!
                  </span>
                </div>
                <p className="text-black text-[38px] font-semibold text-center">
                  Get early access
                </p>

                <form
                  onSubmit={handleSubmit}
                  className="mt-[35px] mb-[14px] mx-auto md:gap-4 gap-2"
                >
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Email Address"
                    className="ease-in duration-300 md:max-w-full md:px-[20px] w-[100%] border-[1px] border-[#CFCFCF] px-[24px] md:py-[23px] py-[20px] bg-[#FAFAFA] text-black rounded-[10px] md:text-three text-[12px] "
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={email === ''}
                    className="ease-in mt-4 flex mx-auto duration-300 hover:opacity-75 cursor-pointer md:px-[45px] px-[35px] text-white md:py-[23px] py-[20px] bg-[#A020EF] rounded-[10px] text-whitee font-semibold md:text-three text-[12px]"
                  >
                    Join Waitlist
                  </button>
                </form>
              </span>
            </div>
          </div>
        </div>
      </div> */}

      <footer className="mt-[110px]">
        <div className="container mx-auto">
          <img src={logo} alt="" className="mx-auto h-[60px]" />
        </div>
        <div className="text-center text-black mt-[50px] font-medium text-[17px]">
          Stay in touch
        </div>
        <div className="text-black flex justify-center items-center p-2 gap-4">
          <RiLinkedinLine
            fontSize={25}
            onClick={openLinkedIn}
            className="cursor-pointer"
          />
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mt-[4px] cursor-pointer"
            onClick={openMail}
          >
            <path
              d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6"
              stroke="#000"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
              stroke="#000"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={openX}
            className="mt-[4px] cursor-pointer"
          >
            <path
              d="M3 21L10.5484 13.4516M10.5484 13.4516L3 3H8L13.4516 10.5484M10.5484 13.4516L16 21H21L13.4516 10.5484M21 3L13.4516 10.5484"
              stroke="#000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={openInsta}
            className="mt-[4px] cursor-pointer"
          >
            <path
              d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
              stroke="#000"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z"
              stroke="#000"
              stroke-width="1.5"
            />
            <path
              d="M17.5078 6.5H17.4988"
              stroke="#000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="text-center text-[#B1B1B1] mt-[62px] mb-[76px]">
          @pitchclerk {JSON.stringify(mm)}
        </div>
      </footer>
    </div>
  );
}

export default App;
